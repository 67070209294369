import React, { useEffect, useState, useContext } from 'react';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import { ipcRenderer } from 'electron';
import Backend from 'i18next-xhr-backend';
import './App.css';
// @ts-ignore
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { Context as SensorContext } from './context/SensorContext';
import { Context as AuthContext } from './context/AuthContext';
import { Context as SettingsContext } from './context/SettingsContext';

import Nav from './components/Nav';
import PrivateRoute, { PrivateRouteProps } from './components/PrivateRoute/PrivateRoute';

import { paths } from './utils/const'

import styles from './main.module.scss'
import ClaimCode from './screens/ClaimCode';

const Account = React.lazy(() => import("./screens/Account"));
const Dashboard = React.lazy(() => import("./screens/Dashboard"));
const DeviceDetails = React.lazy(() => import("./screens/DeviceDetails"));
const Settings = React.lazy(() => import("./screens/Settings"));

let translationPath = 'https://sensei.pierasystems.com/locales/{{lng}}/{{ns}}.json';
if (process.env.NODE_ENV === "development")
  translationPath = 'http://localhost:3000/locales/{{lng}}/{{ns}}.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: translationPath,
    },
  });

function Main(props: any) {
  // const { t } = useTranslation();
  const currentLocation = useLocation();

  const {
    state,
    fetchDevices,
    fetchMyDevices,
    setCurrentDevice,
    clearError
  } = useContext(SensorContext);

  const {
    state: authState,
    checkSignin,
    signout,
    setRedirectPath
  } = useContext(AuthContext);

  const {
    refreshSettings
  } = useContext(SettingsContext);

  const [deviceOption, setDeviceOption] = useState(-1);
  // const [legend, setLegend] = useState(false);

  useEffect(() => {
    // checkSignin();
    refreshSettings();
  }, []);

  useEffect(() => {
    // checkSignin();
    // console.log("Main: fetchDevices effect hook: ", authState.signin)
    async function tick() {
      if (!authState.signin) {
        // console.log("Main: tick: fetching devices ", authState.signin)
        // await fetchDevices();
      } else {
        // console.log("Main: tick: fetching my devices ", authState.signin)
        await new Promise(r => setTimeout(r, 500));
        // checkSignin();
        await fetchMyDevices();
        // console.log("authError after fetchMyDevices: ", state.authError)
        // if (state.authError) {
        //   console.log("Main, state.authError: signing out")
        //   signout();
        //   clearError();
        // }
      }
    }
    tick();
    const timer = setInterval(() => {
      tick();
    }, 30 * 1000);
    return (() => {
      clearInterval(timer);
    });
  }, [authState.signin, state.authError]);

  useEffect(() => {
    // console.log(state.devices);
    if (deviceOption === -1 && state.devices && state.devices.length > 0) {
      setDeviceOption(state.devices[0].did);
      setCurrentDevice(state.devices[0].did);
    }
    if (state.devices && state.devices.length && !state.devices.find((o: any) => o.did === deviceOption)) {
      setDeviceOption(state.devices[0].did);
      setCurrentDevice(state.devices[0].did);
    }
  }, [state.devices, deviceOption]);

  // console.log("!!authState.signin", !!authState.signin)

  const defaultPrivateRouteProps: PrivateRouteProps = {
    isAuthenticated: !!authState.signin,
    authenticationPath: '/auth/login',
    redirectPath: currentLocation.pathname,
    setRedirectPath: (path: string) => { setRedirectPath(path); }
  };

  return (
    <div className={styles.main}>
      <Nav />
      <main className={styles.content}>
        <Switch>
          <Route path={paths.dashboard} render={(props: any) => <Dashboard {...props} deviceOption={deviceOption} />} />
          <Route path={paths.account} render={(props: any) => <Account {...props} />} />
          <Route path={paths.details} render={(props: any) => <DeviceDetails {...props} />} />
          <Route path={paths.settings} render={(props: any) => <Settings {...props} />} />
          <PrivateRoute {...defaultPrivateRouteProps} path={paths.claimCode} ><ClaimCode {...props} /></PrivateRoute>
          <Redirect to={paths.auth} />
        </Switch>
      </main>
    </div>
  );
}

export default Main;