export const paths = {
  root: '/',
  auth: '/auth',
  login: '/auth/login',
  signup: '/auth/signup',
  setNewPassword: '/auth/set-new-password',
  setNewPasswordToken: '/auth/set-new-password/:token?',
  claimCode: '/claim-code/:claimCode',
  dashboard: '/dashboard',
  details: '/device/:did',
  profile: '/profile',
  account: '/account',
  settings: '/settings',
  support: 'https://pierasystems.com/support/',
}
