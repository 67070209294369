import React, { useContext, useEffect, useState } from 'react'
import { Context as SensorContext } from '../../context/SensorContext'
import { Context as AuthContext } from '../../context/AuthContext'
import { Context as SettingsContext } from '../../context/SettingsContext'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { IoNotifications } from 'react-icons/io5'
import { BsCircleFill } from 'react-icons/bs'
import { CgProfile } from 'react-icons/cg'
import { FiChevronDown } from 'react-icons/fi'
import { HiViewGrid } from 'react-icons/hi'

import { FaUserEdit } from 'react-icons/fa'

import { MdSpaceDashboard } from 'react-icons/md'
import { IoInformationCircle } from 'react-icons/io5'
import { IoSettings } from 'react-icons/io5'

import { paths } from '../../utils/const'

import styles from './nav.module.scss'
const {version} = require('../../../package.json')

function Nav() {
  const { t } = useTranslation();
  const { state } = useContext(SensorContext)
  const { state: authState, signout } = useContext(AuthContext);
  const { state: language } = useContext(SettingsContext);
  const location = useLocation();
  const [screenTitle, setScreenTitle] = useState('')
  const [hasNotifications, setHasNotifications] = useState(true)
  // const [notificationsLength, setNotificationsLength] = useState(5)

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)


  // useEffect run on Route Change
  useEffect(() => {
    function getScreenTitle(pathname: any):string {
      if (pathname === '/dashboard') {
        return t('dashboard')
      } else if (pathname === '/settings') {
        return t('settings')
      } else if (pathname === '/account') {
        return t('account-info')
      } else {
        return t('device-details')
      }
    }
    setIsMobileMenuOpen(false)
    setIsProfileMenuOpen(false)
    setScreenTitle(getScreenTitle(location.pathname))
    setHasNotifications(true)
    // setNotificationsLength(5)
  }, [location.pathname, language])

  return (
    <>
      <div className={styles.mainBar}>
        <img className={styles.logoImage} src={'/logo512.png'} alt='' />
        <b className={styles.navTitle}>{screenTitle}</b>
          <div className={styles.navIcons}>
            {authState.signin ?
              <>
                <div className={styles.iconGroup}>
                  <IoNotifications className={styles.iconNotificationBell} />
                  <BsCircleFill className={`${styles.iconNotificationDot} ${(hasNotifications ? styles.hasNotifications : '')}`} />
                  {/* <p className={`${styles.notificationsLength} ${(hasNotifications ? styles.hasNotifications : '')}`}>{notificationsLength}</p> */}
                </div>
                <div 
                  className={styles.iconGroup}
                  onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                >
                  <CgProfile className={styles.iconProfile} />
                  <FiChevronDown 
                    className={styles.iconChevron} 
                  />
                </div>
              </>
            : <>
              <NavLink
                className={`${styles.mainBarButton}`}
                to={paths.login}
              >{t('login')}</NavLink>
              <NavLink
                className={`${styles.mainBarButton}`}
                to={paths.signup}
              >{t('create-account')}</NavLink>
            </> }
          </div>
        <HiViewGrid 
          className={styles.iconMobileMenu} 
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} 
        />
      </div>
      <div className={styles.navBar}>
        <img className={styles.logoImage} src={'/logo512.png'} alt='Piera Systems Logo' />
        <nav>
          {authState.signin ?
            <NavLink exact className={styles.navLink} activeClassName={styles.active} to={paths.dashboard}>
              <MdSpaceDashboard className={styles.navLinkIcon} />{t('dashboard')}
            </NavLink>
          : <></>}
          <NavLink className={styles.navLink} activeClassName={styles.active} to={`/device/${state.currentDevice}`}>
            <IoInformationCircle className={styles.navLinkIcon} />{t('details')}
          </NavLink>
          <NavLink className={styles.navLink} activeClassName={styles.active} to={paths.settings}>
            <IoSettings className={styles.navLinkIcon} />{t('settings')}
          </NavLink>
        </nav>
        <p className={styles.appVersionNumberLabel}>{version}</p>
      </div>
      <nav className={`${styles.mobileNavBar} ${isMobileMenuOpen ? styles.mobileNavBarOpen : styles.mobileNavBarClosed}`}>
          {authState.signin ?
            <NavLink 
              className={styles.navProfileGroup}
              to={paths.account}
            >
              <CgProfile className={styles.iconProfile} />
              <FaUserEdit className={styles.iconEditProfile} />
            </NavLink>
            : <></> }
        <div className={styles.navLinkGroup}>
          {authState.signin ?
            <NavLink  
              className={styles.navLink} 
              activeClassName={styles.active} 
              to={paths.dashboard} exact
            >
              <MdSpaceDashboard className={styles.navLinkIcon} />{t('dashboard')}
            </NavLink>
          : <></> }
          <NavLink 
            className={styles.navLink} 
            activeClassName={styles.active} 
            to={`/device/${state.currentDevice}`}
          >
            <IoInformationCircle className={styles.navLinkIcon} />{t('details')}
          </NavLink>
          <NavLink 
            className={styles.navLink} 
            activeClassName={styles.active} 
            to={paths.settings}
          >
            <IoSettings className={styles.navLinkIcon} />{t('settings')}
          </NavLink>
        </div>
        <div className={styles.mobileProfileLinks}>
          <a 
            className={styles.profileMenuLink}
            href={paths.support}
          >{t('support')}</a>
          {!authState.signin ?
            <>
              <NavLink
                className={`${styles.profileMenuLink}`}
                to={paths.login}
              >{t('login')}</NavLink>
              <NavLink
                className={`${styles.profileMenuLink}`}
                to={paths.signup}
              >{t('create-account')}</NavLink>
            </>
          : <button 
              className={`${styles.profileMenuLink} ${styles.signoutButton}`}
              onClick={() => signout()}
            >{t('logout')}</button>
          }
        </div>
        <p className={styles.appVersionNumberLabel}>{version}</p>
      </nav>
      {authState.signin ?
        <div className={`${styles.profileMenu} ${isProfileMenuOpen ? styles.profileMenuOpen : styles.profileMenuClosed}`}>
          <div className={styles.profileMenuLinkGroup}>
            <NavLink 
              className={styles.profileMenuLink}
              to={paths.account}
            >
              {t('account')}
            </NavLink>
          </div>
          <div className={styles.profileMenuDivideLine}></div>
          <div className={styles.profileMenuLinkGroup}>
            <a 
              className={styles.profileMenuLink}
              href={paths.support}
            >
              {t('support')}
            </a>
          </div>
          <div className={styles.profileMenuDivideLine}></div>
          <div className={styles.profileMenuLinkGroup}>
            <button 
              className={`${styles.profileMenuLink} ${styles.signoutButton}`}
              onClick={() => signout()}
            >
              {t('logout')}
            </button>
          </div>
        </div>
      : <></> }
    </>
  );
}

export default Nav;
